import STATUS from '../../globalStatuses';
import {
  REQUEST_TRANSFER_CREDITS_DATA,
  RECEIVE_TRANSFER_CREDITS_DATA,
  REQUEST_TRANSFER_CREDITS_ERROR,
} from './types';

const cleanCourseId = (id) => id.replace(/[^a-zA-Z0-9 ]/g, '').replace(' ', '');
const removeTabsFromTitle = (title) => title.replace(/\t/g, '');

const isCourseMatch = (course, transferCourse) => {
  const dpCourseId = course.activity.id ? cleanCourseId(course.activity.id) : '';
  const tcCourseId = transferCourse.courseId ? cleanCourseId(transferCourse.courseId) : '';
  const tcCourseTitle = transferCourse.title ? removeTabsFromTitle(transferCourse.title) : '';
  const dpCourseTitle = course.activity.name ? removeTabsFromTitle(course.activity.name) : '';
  return tcCourseId === dpCourseId
    && transferCourse.orgaName?.toUpperCase() === course.activity?.institutionName?.toUpperCase()
    && tcCourseTitle?.toUpperCase() === dpCourseTitle.toUpperCase();
};

const isRequirementMatch = (course, transferCourse) => {
  if (!course?.requirementDetails?.length || !transferCourse.programDetails?.length) return true;
  return course?.requirementDetails?.some((reqDetail) => (
    transferCourse.programDetails?.some((progDetail) => {
      const progDetailSplit = reqDetail.requirement?.fullName.split('|');
      const reqDetailName = progDetailSplit[progDetailSplit.length - 1];
      return reqDetailName?.toUpperCase().startsWith(progDetail.comments?.toUpperCase())
        && progDetail.creditsApplied === reqDetail.creditsSummary?.applied;
    })
  ));
};

const createCourseObj = (course, transferCourse, appliedCredits, label) => ({
  courseId: transferCourse.courseId,
  title: course?.activity.name,
  applied: appliedCredits,
  label,
  grade: course?.activity?.grade,
  levelType: course?.activity?.creditLevelCode,
  orgaName: transferCourse.orgaName || '',
  creditsEarned: appliedCredits,
  creditsAttempted: transferCourse.creditsAttempted || 0,
  creditsAccepted: transferCourse.creditsAccepted || 0,
  creditsApplied: transferCourse.creditsAccepted === 'Y' ? appliedCredits : 0,
  nonTransferReason: transferCourse.nonTransferReason || '',
});

const getTransferCredits = (auditDetails, transfer) => {
  const indexedTransferArr = {};
  if (auditDetails) {
    Object.entries(auditDetails).forEach(([keyPid, detailPindexedArr]) => {
      const transferCourses = [];
      const filteredArr = detailPindexedArr?.filter((college) => (
        college.activity.institutionName
          && college.activity.institutionName.toUpperCase() !== 'UNIVERSITY OF PHOENIX')) || [];

      const remainingTransfers = [...transfer];
      const stack = [...filteredArr];

      while (stack.length > 0) {
        const course = stack.pop();
        for (let i = 0; i < remainingTransfers.length; i += 1) {
          const transferCourse = remainingTransfers[i];
          if (isCourseMatch(course, transferCourse) && isRequirementMatch(course, transferCourse)) {
            if (!course?.requirementDetails?.length || !transferCourse.programDetails?.length) {
              transferCourses.push(
                createCourseObj(course, transferCourse, transferCourse.creditsEarned || 0, ''),
              );
            } else {
              course.requirementDetails.forEach((reqDetail) => {
                const matchingProgramDetail = transferCourse.programDetails.find((progDetail) => {
                  const reqDetailSplit = reqDetail.requirement.fullName.split('|');
                  const reqDetailName = reqDetailSplit[reqDetailSplit.length - 1];
                  return reqDetailName?.toUpperCase().startsWith(progDetail.comments?.toUpperCase())
                    && progDetail.creditsApplied === reqDetail.creditsSummary?.applied;
                });
                if (matchingProgramDetail) {
                  transferCourses.push(createCourseObj(
                    course,
                    transferCourse,
                    matchingProgramDetail.creditsApplied,
                    reqDetail.requirement.name,
                  ));
                }
              });
            }
            break;
          }
        }
      }
      indexedTransferArr[keyPid] = transferCourses;
    });
  }
  return indexedTransferArr;
};

export default function transferCreditsData(state = {}, action) {
  switch (action.type) {
    case REQUEST_TRANSFER_CREDITS_DATA:
      return {
        ...state,
        receivedAt: action.receivedAt,
        status: STATUS.FETCHING,
      };
    case RECEIVE_TRANSFER_CREDITS_DATA: {
      return {
        ...state,
        receivedAt: action.receivedAt,
        byProgramId: getTransferCredits(action.indexedAuditDetailsArr,
          action.transferCreditResponseData),
        status: STATUS.FETCHED,
      };
    }
    case REQUEST_TRANSFER_CREDITS_ERROR: {
      return {
        ...state,
        status: STATUS.ERROR,
        receivedAt: action.receivedAt,
      };
    }

    default:
      return state;
  }
}
